.passwordHead {
  background-color: white;
  width: 50%;
  margin: 20px auto;
  border-radius: 5px;
  padding: 10px;
}
.passwordHead button {
  display: flex;
  margin: 10px 15px 0px auto;
  font-family: SansB !important;
  color: white;
}
.form-group .col-sm-12 {
  padding: 5px 0px !important;
}
.form-group input {
  font-family: sansL;
}
.password-strength {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  top: 5px;
  right: 10px;
}
.password-strength-text {
  font-size: 11px;
  margin: 0 0.3rem;
  font-family: sansL;
}
.linear-progress {
  width: 70px;
  height: 10px;
  border-radius: 2px;
}
@media screen and (max-width: 700px) {
  .passwordHead {
    background-color: white;
    width: 95%;
    margin: 10px;
    border-radius: 5px;
    padding: 10px;
  }
  .passwordHead button {
    display: flex;
    margin: 10px 15px 0px auto;
    font-family: SansB !important;
    color: white;
  }
}
