@font-face {
  font-family: Sans;
  src: url(../../fonts/ProductSans-Regular.ttf);
}

@font-face {
  font-family: SansSB;
  src: url(../../fonts/ProductSans-Medium.ttf);
}

@font-face {
  font-family: SansB;
  src: url(../../fonts/ProductSans-Bold.ttf);
}

@font-face {
  font-family: SansL;
  src: url(../../fonts/ProductSans-Light.ttf);
}

.container {
  margin: 0;
  padding: 0 30px;
  max-width: 100%;
}

.row {
  max-width: 100%;
  margin: 0% !important;
}

.loginMain {
  font-family: Sans;
}

.mainLogo {
  background-image: url(../../images/IN-D-Logo-LightBackground.png);
  background-repeat: no-repeat;
  height: 60px;
  width: 180px;
  margin: 15px;
}

.logintext {
  color: #048c88;
  font-size: 20px;
  font-family: SansB;
  max-width: 300px;
  text-align: center;
  display: flex;
  align-items: center;
}

.loginHead {
  display: flex;
  align-items: center;
  background-color: white;
}

#loginBody {
  background-color: #e2eaea !important;
  width: 100%;
  padding: 20px;
}

#loginForm h5 {
  color: #3d3f43;
  font-size: 40px;
  font-family: "SansL";
}
#loginBody h4 {
  font-size: 26px;
  color: #000;
  display: block;
  font-weight: 700;
  margin-top: 20px;
}

#name {
  display: block;
}

#pass {
  display: block;
}

#pass a {
  margin-left: 350px;
}

label {
  color: #3d3f43;
  font-size: 14px;
  font-family: SansSB;
}

.textInput {
  height: 40px;
  width: 100%;
  border: solid 1px #dadada;
  padding: 0 15px;
}

#loginForm input {
  font-family: SansL !important;
  color: #404040;
}

.field-icon {
  margin-left: -40px;
  cursor: pointer;
}

.fa-eye {
  color: #c1c7d0;
}

#signInButton {
  display: block;
  height: 36px;
  width: 100%;
  background-color: #00716e;
  border: none;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-family: ProximaNovaB !important;
  margin-top: 30px;
}

#checkBox {
  margin-top: 25px;
}

input[type="checkbox"] {
  height: 13px;
  width: 20px;
  background-color: white !important;
}

#newFull {
  margin-top: 30px !important;
}

#new {
  color: #63666b;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  position: relative;
  margin: 0px !important;
  padding: 5px 0px !important;
}

.loginhr {
  width: 30%;
  background-color: #d1d1d1;
}

#createButton {
  display: block;
  height: 40px;
  width: 100%;
  background-color: white;
  border: solid 1px #e2e2e2;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
  transition: 0.2s;
  font-family: Sans !important;
}

#createButton:hover {
  background-color: #00716e;
  color: white;
}

.errormsg {
  color: red;
  font-family: SansSB;
  font-size: 14px;
  width: 85%;
  margin: auto;
}
#signInButton:disabled {
  background-color: grey;
}
.hide {
  width: 26px;
  cursor: pointer;
  position: absolute;
  margin-top: -52px;
  margin-left: -5px;
}

.hide_div {
  display: flex;
  justify-content: flex-end;
}
.routing {
  display: flex;
  justify-content: flex-end;
  color: #048c88 !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.ForgetClass img {
  cursor: pointer;

  width: 50px;
  height: 50px;
  background-color: #f2f5fa;
  border-radius: 50%;
  padding: 16px;
  margin-right: auto !important;
}
.forgetmsg {
  font-family: "SansL";
}

.ForgetHeader {
  /* width: 40%; */
  margin: auto;
}
#name {
  margin-top: 20px;
}
@media screen and (max-width: 700px) {
  .ForgetHeader {
    width: 100%;
  }
}
/*new css*/
.Login_container {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin: auto;
  height: 430px;
  width: 420px;
}
.Login_header:nth-child(1) {
  font-size: 47px;
  margin-top: 5px;
}
.Login_form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 6px 30px #57949473;
  border-radius: 10px;
  padding: 45px 0;
}
.Login_header {
  display: flex;
  align-items: flex-end;
  margin: auto;
  font-family: ProximaNovaL;
  font-size: 25px;
  color: #3e3e3e;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.textInput {
  height: 40px;
  width: 85%;
  border: solid 1px #dadada;
  padding: 0 15px;
  margin: auto;
  display: flex;
  border-radius: 4px;
  margin-top: 26px;
  font-family: "ProximaNovaL";
  font-size: 16px;
  font-weight: 500;
}
.Login_form form button {
  display: flex;
  margin: 30px 10px auto auto;
  font-family: ProximaNovaR;
  color: white !important;
  text-transform: none;
}
.Login_form form a {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  color: #048c88;
  font-size: 16px;
  font-family: ProximaNovaS;

  margin-bottom: 13px !important;
}
.Login_form button:disabled {
  background-color: #00716e !important;
  font-family: ProximaNovaB;
}
.footer a {
  color: #808080;
  font-size: 12px;
  padding-right: 10px;
}
.footer a:hover,
.register a:hover {
  color: #048c88;
  font-family: ProximaNovaB;
}
.footer {
  text-align: end;
  font-family: ProximaNovaS;
  padding-top: 4%;
}
.register p {
  color: #808080;
  font-size: 13px;
  font-family: ProximaNovaS;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 15px;
  width: 100%;
  text-align: center;
}
.register a {
  color: #048c88;
  font-size: 13px;
  font-family: ProximaNovaB;
  margin-left: 5px;
}
.hide {
  width: 26px;
  cursor: pointer;
  position: absolute;
  margin-top: -35px;
  margin-right: 35px;
}
.hide_div {
  display: flex;
  justify-content: flex-end;
}
.Login_header img {
  width: 139px;
  height: 50px;
  margin-right: 15px;
}
.Toastify_toast--error .Toastify_toast--success {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  font-family: ProximaNovaB !important;
}
.Toastify__toast {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  font-family: ProximaNovaB !important;
}
.submitbutton {
  margin-right: 2rem !important;
}
.submitbutton .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  color: white;
}
.showPasswordContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
}
.showPassword {
  display: flex;
  font-size: 9px;
  align-items: center;
}
.showPassword > input {
  height: 10px;
  width: 10px;
  margin-right: 0.3rem;
}
.forgotPass {
  font-size: 9px !important;
  font-family: ProximaNovaB !important;
  cursor: pointer !important;
}

.Login_container {
  width: 29%;
  position: absolute;
  top: 17%;
  left: 0;
  right: 0;
  margin: auto;
}
.Login_form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 6px 30px #57949473;
  border-radius: 12px;
  /* padding: 30px 45px 45px 45px; */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login_header {
  display: flex;
  align-items: center;
  margin: auto;
  font-family: ProximaNovaL !important;
  font-size: 25px;
  color: #3e3e3e;
  justify-content: center;
  /* align-items: end; */
}
.Login_header h2 {
  font-family: ProximaNovaL;
}
.textInput {
  height: 40px;
  width: 85%;
  border: solid 1px #dadada;
  padding: 0 15px;
  margin: auto;
  display: flex;
  border-radius: 6px;
  margin-top: 17px;
  font-family: proximaNovaL;
  font-size: 15px;
}
.Login_form form button {
  display: flex;
  margin: 30px 10px auto auto;
  font-family: ProximaNovaB;
  color: white !important;
}

.Login_form form a {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  color: #048c88;
  font-size: 16px;
  font-family: ProximaNovaS;
}
.footer a {
  color: #808080;
  font-size: 12px;
  padding-right: 10px;
  text-decoration: none;
}
.footer {
  padding: 15px;
  text-align: end;
  font-family: ProximaNovaS;
  padding-top: 5%;
}
.register p {
  color: #808080;
  font-size: 13px;
  font-family: ProximaNovaS;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 15px;
  width: 100%;
  text-align: center;
}
.register a {
  color: #048c88;
  font-size: 13px;
  font-family: ProximaNovaB;
  margin-left: 5px;
}
.hide {
  width: 18px;
  cursor: pointer;
  position: absolute;
  margin-top: -30px;
  margin-right: 35px;
}
.hide_div {
  display: flex;
  justify-content: flex-end;
  margin-right: -0.1rem;
}
.Login_header img {
  width: 45%;
  margin-right: 15px;
}
.header {
  font-family: ProximaNovaB;
  font-size: 25px;
}
.Toastify__toast--error .Toastify__toast--success {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  font-family: ProximaNovaB !important;
}
.Toastify__toast {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  font-family: ProximaNovaB !important;
}

input.error {
  border-color: red !important;
  font-family: ProximaNovaS;
}

.input-feedback {
  color: rgb(235, 54, 54);
  font-size: 14px;
  margin: 5px 12px;
  font-family: ProximaNovaS;
}

.Signup_container {
  width: 35%;
}
.input-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.input-with-error {
  width: 100%;

  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
}

.input-with-error input {
  width: 98%;
}
.input-with-error p {
  margin-left: 0.5rem;
  font-size: 10px;
}
.agree-terms {
  display: flex;
  align-items: center;
  font-size: 11px;
  margin-left: 0.3rem;
  /* background-color: blue; */
}

.agree-terms input[type="checkbox"] {
  margin-right: 0.5rem;
}
.password-strength {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  top: 5px;
  right: 10px;
}
.linear-progress {
  width: 70px;
  height: 10px;
}
.signinbutton {
  font-family: ProximaNovaB !important;
}
.signinbutton:disabled {
  opacity: 0.5 !important;
  background-color: cadetblue !important;
}
.password-strength-text {
  font-size: 11px;
  margin: 0 0.3rem;
}

@media (max-width: 599px) {
  .Login_container {
    width: 90%;
  }
}
@media (max-width: 1200px) and (min-width: 600px) {
  .Login_container {
    width: 50%;
  }
}
