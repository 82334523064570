body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2eaea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: Sans;
  src: url(./fonts/ProductSans-Regular.ttf);
}

@font-face {
  font-family: SansSB;
  src: url(./fonts/ProductSans-Medium.ttf);
}

@font-face {
  font-family: SansB;
  src: url(./fonts/ProductSans-Bold.ttf);
}

@font-face {
  font-family: SansL;
  src: url(./fonts/ProductSans-Light.ttf);
}

@font-face {
  font-family: ProximaNovaB;
  src: url(./fonts/ProximaNova-Bold.ttf);
}
@font-face {
  font-family: ProximaNovaR;
  src: url(./fonts/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaNovaS;
  src: url(./fonts/ProximaNova-Semibold.ttf);
}
@font-face {
  font-family: ProximaNovaL;
  src: url(./fonts/ProximaNova-Light.ttf);
}
@media screen and (max-width: 700px) {
  .Toastify__toast {
    margin-bottom: 60px !important;
  }
}
.Toastify__toast {
  border-radius: 10px !important;
  font-family: SansB !important;
  font-size: 13px !important;
}
