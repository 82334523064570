.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aadhar-details-info > span {
  display: block;
  font-family: GooglesansBold;
}
.aadhar-details-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
}

.aadhar-input {
  width: 100%;
  border: none;
  border-color: transparent;
  padding: 8px 0;
  font-family: SansSB;
  font-size: 14px;
  color: #122626;
}
.name-input {
  width: 100%;
}
.details-label {
  width: 85%;
  color: #516463;
  font-size: 13px;
}
.edit-pen {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.sm-pen-round {
  border-radius: 100px;
  border: 1px solid #048c88;
  background-color: #ffffff;
  margin: -10px 15px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.MuiDialog-paperWidthSm {
  width: 60%;
}
.MuiDialog-paperWidthSm button {
  font-family: SansB;
}
.MuiTabs-flexContainer button {
  font-family: SansB;
}
.gst {
  margin: auto;
}
.dialogbutton {
  border: 1px solid red !important;
}
.deletealert {
  font-family: SansSB;
}
.datepicker {
    width: 230px !important;
}
@media screen and (max-width: 700px) {
  .MuiDialog-paperWidthSm {
    width: 100%;
  }
  .gst {
      margin: initial;
  }
}
.MuiOutlinedInput-input {
  height: 12px !important;
}
