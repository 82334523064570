a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.wrapper {
  /* display: flex;
    align-items: stretch; */
  /* padding: 30px; */
  margin-left: 60px !important;
  /* overflow-x: hidden !important; */
  /* margin-top: 50px; */
}

.main {
  font-family: Sans;
  background-color: #e2eaea !important;
  min-height: 100vh !important;
}

.row {
  max-width: 100% !important;
  margin: 0% !important;
}

#productsHead {
  height: 64px;
  background-color: #ffffff;
  width: 100% !important;
  box-shadow: 0px 2px 3px #00000029;
  position: relative;
  /* z-index: 99; */
}

#productsHead h5 {
  font-size: 18px;
  padding: 24px;
  padding-left: 22px !important;
  font-family: Sans;
  color: #2c2c2c;
  margin: 0px !important;
  /* width: fit-content; */
}

/* #productsMain {
    height: 90vh !important;
} */

/* Left */

/* #productsMain #productsLeft {
  padding: 20px !important;
} */

#productsMain #productsLeft h4 {
  font-size: 20px;
  font-family: Sans;
  color: #2c2c2c;
  margin: 0px !important;
}

#myProdList {
  width: 100%;
  /* margin: 20px !important; */
  /* margin-top: 22px !important; */
}

#productsMain .grid-container {
  /* display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 20px;
  align-content: space-evenly; */
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

#myProdEach {
  background-color: white;
  /* height: 270px; */
  border-radius: 10px !important;
  position: relative;
  box-shadow: 0px 4px 16px #425e5d1a;
  margin-bottom: 20px !important;
  margin: 10px;
}

#myProdEach h6 {
  font-size: 16px;
  font-family: SansSB;
  color: #2c2c2c;
  margin-top: 25px !important;
  text-align: center !important;
}

#myProdEach #blueCircle {
  background-color: #e1fcfb !important;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin-top: 7px !important;
  text-align: center;
  line-height: 100px;
}

#myProdEach #openButton {
  height: 28px;
  width: 70%;
  background-color: white;
  border: #00716e 1px solid;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-top: 15px;
  /* margin-bottom: 15px; */
}

#myProdEach #openButton:hover {
  background-color: #00716e;
  color: white;
}

#myProdEach #bottom {
  background-color: #dff1f0 !important;
  /* position: absolute; */
  bottom: 0px !important;
  height: 34px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px;
  cursor: pointer;
}

#myProdEach #bottom p {
  color: #00716e;
  text-align: center;
  font-size: 12px;
  font-family: Sans !important;
}

#productsLeft #explore {
  background-color: #f6fefe;
  height: 300px;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-top: 30px;
  padding: 50px;
}

#productsLeft #explore h4 {
  color: #2c2c2c;
  text-align: center;
  font-size: 30px;
  font-family: Sans !important;
  margin-top: 30px !important;
}

#productsLeft #explore h6 {
  color: #2c2c2c;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-top: 20px;
}

#productsLeft #explore #exploreButton {
  height: 37px;
  width: 200px;
  background-color: #f6fefe;
  border: #00716e 1px solid;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-top: 30px;
}

#productsLeft #explore #exploreButton:hover {
  background-color: #00716e;
  color: #f6fefe;
}

/* Right */

#productsMain #productsRight {
  background-color: #d6dede !important;
  padding: 20px !important;
}

#productsMain #productsRight h4 {
  font-size: 20px;
  font-family: Sans;
  color: #2c2c2c;
  margin: 0px !important;
}

#recProducts #recProductsEach {
  margin-top: 20px !important;
  /* height: 140px; */
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px #425e5d1a;
  padding: 20px !important;
}

#recProducts #recProductsEach #blueCircle {
  background-color: #e1fcfb !important;
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

#recProducts #recProductsEach #right {
  margin-left: 15px !important;
  width: 60%;
}

#recProducts #recProductsEach #right h6 {
  font-size: 16px;
  font-family: SansSB;
  color: #2c2c2c;
}

#recProducts #recProductsEach #right p {
  font-size: 12px;
  font-family: Sans;
  color: #516463;
}

#recProducts #recProductsEach #right #addButton {
  height: 25px;
  width: 53px;
  background-color: white;
  border: #00716e 1px solid;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 12px;
  font-family: Sans !important;
  /* margin-top: 10px; */
}

#recProducts #recProductsEach #right #addButton:hover {
  background-color: #00716e;
  color: white;
}

#recProducts #recProductsEach #right #prodDet {
  color: #00716e !important;
  margin-left: 30px !important;
  margin-top: 3px !important;
  margin-bottom: 0px !important;
  cursor: pointer;
}

.form-group {
  margin-bottom: 0px !important;
}
#dropdown {
  padding-right: 0px;
  padding-left: 0px;
}
#popupformsubmit {
  display: flex;
}

#bpcKeyButton {
  line-height: 28px;
  width: 44%;
  background-color: white;
  border: #00716e 1px solid;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-top: 12px;
  margin-bottom: 7px;
  margin-right: 4% !important;
}
.generateapikey {
  margin-bottom: 19px !important;
}
#bpcKeyButton:hover {
  background-color: #00716e;
  color: white;
}
#apiKeyButton {
  height: 28px;
  width: 22%;
  background-color: white;
  border: #00716e 1px solid;
  border-radius: 4px;
  color: #00716e;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-top: 15px;
  margin-bottom: 25px;
}
#apiKeyButton:hover {
  background-color: #00716e;
  color: white;
}

/* Modal */

.keyModal.modal {
  background: rgba(0, 0, 0, 0.6);
}

.keyModal.modal .modal-title {
  color: #132121 !important;
  font-size: 18px !important;
  font-family: SansSB !important;
}

.addModal.modal .modal-content {
  height: inherit !important;
  border-radius: 0px !important;
}

.keyModal.modal .modal-body {
  padding: 20px !important;
}

.keyModal.modal .form-control {
  height: 32px !important;
  color: #516463 !important;
  font-family: Sans !important;
  font-size: 14px !important;
  /* color: #122626 !important; */
}

.keyModal.modal .form-group {
  margin-bottom: 0px !important;
}

.keyModal.modal .modal-footer {
  background-color: #edf3f3;
  justify-content: flex-start !important;
  padding-left: 20px !important;
}
#expirycolumn {
  padding-top: 12px;
}
.modal-footer {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: center;
}
.saveButton {
  width: 120px;
  color: white;
  border: #048c88 1px solid;
  border-radius: 4px;
  background-color: #048c88;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  height: 40px;
}
.closebutton {
  width: 90px !important;
  height: 34px !important;
}

.keyModal.modal #cancelButton {
  height: 32px;
  width: 85px;
  background-color: white;
  border: #bdc8c8 1px solid;
  border-radius: 4px;
  color: #048c88;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
  margin-left: 10px !important;
}
#myProdEach h6 {
  text-transform: capitalize;
}

.textInputforcreate {
  height: 40px;
  width: 100%;
  border: solid 1px #dadada;
  padding: 0 10px;
}

select.textInputforcreate {
  text-transform: capitalize;
}
#copyBtn {
  padding: 5px 5px !important;
  min-width: 30px;
}

.MuiTableCell-head {
  background-color: #dff2f1 !important;
  padding: 10px !important;
  padding: 10px 20px !important;
  color: #122626 !important;
  font-size: 12px !important;
  font-family: SansSB !important;
  text-transform: uppercase;
}

.MuiTableCell-body {
  color: #516463 !important;
  font-size: 14px !important;
  font-family: Sans !important;
  /* padding: 10px 20px !important; */
}
#applicationList .MuiTableRow-root svg {
  width: 15px;
  height: 15px;
}

.MuiTypography-h6 {
  font-size: 18px;

  font-family: Sans !important;
  color: #2c2c2c;
  margin: 0px !important;
}

.keyModal.modal .saveButton:disabled {
  height: 32px;
  width: 120px;
  color: #333;
  border: 1px solid #dadada;
  border-radius: 4px;
  background-color: #ebebe4;
  text-align: center;
  font-size: 14px;
  font-family: Sans !important;
}
.MuiPaper-rounded {
  box-shadow: 0px 4px 16px #425e5d1a !important;
  border-radius: 10px !important;
}
#sendKeyBymail {
  display: block;
  margin: auto;
  float: left;
  width: 100%;
}
.charectorLimit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.MuiTableCell-root.MuiTableCell-body button + button {
  margin-left: 10px;
}
.filecopy {
  font-size: 15px;
}
.MuiButton-outlinedPrimary {
  border: none !important;
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: #048c88 !important;
}
#deletebutton:hover {
  color: red !important;
}
@media screen and (max-width: 700px) {
  #productsMain .grid-container {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
  }
  #productsHead {
    background-color: #e2eaea !important;
    box-shadow: none;
  }
  #myProdEach {
    width: 330px;
    margin: 2px;
  }
  #bpcKeyButton {
    width: 60%;
  }
  .MUIDataTablePagination-root-191:last-child {
    padding: 0px !important;
  }
  .MUIDataTableBodyCell-stackedCommon-188 {
    width: calc(50% - 80px);
    display: inline-block;
    font-size: 16px;
    white-space: nowrap;
    height: auto !important;
  }
  .MUIDataTableBodyCell-cellStackedSmall-189 {
    width: 26% !important;
  }
  .modal-dialog {
    padding-top: 70px;
    box-shadow: 0px 0px 20px 20px rgb(8, 8, 8);
  }
}
.error {
  color: red;
  font-size: 15px;
  font-family: SansSB !important;
}
