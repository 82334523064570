.SignupWithMicrosoft_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding-top: 5%;
}
.signinWithMicrosoftBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signinWithMicrosoftBtnSubContainer {
  border: solid 1px #dadada !important;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin: 0.6rem;
  cursor: pointer;
  font-family: sansL;
}
.signinWithMicrosoftBtnSubContainer img {
  margin-right: 0.5rem;
}
.header-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-family: SansL;
}

.main-text {
  font-weight: bolder;
  margin-left: 0.5rem;
}
.SignupWithMicrosoft_container h5 {
  font-weight: 400;
  padding-bottom: 1rem;
}
.logo {
  position: absolute;
  bottom: 20%;
}
.SignupWithMicrosoft_footer {
  position: absolute;
  right: 10px;
  bottom: 30px;
  width: 30%;
  display: flex;
  justify-content: space-around;
  font-family: sansL;
}
.SignupWithMicrosoft_footer a {
  color: #808080;
  font-size: 12px;
  text-decoration: none;
}

.SignupWithMicrosoft_register p {
  color: #808080;
  font-size: 13px;
  font-family: sanS;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 15px;
  width: 100%;
  text-align: center;
}
.thankyoutext {
  font-family: sanS;
  text-align: center;
}
.thankyoutext span {
  font-family: sansB;
}
@media screen and (max-width: 700px) {
  .header-text {
    display: flex;
    flex-direction: column;
  }
  .SignupWithMicrosoft_footer {
    width: 100%;
    right: 0px;
  }
}
