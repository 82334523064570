.loader-wrapper {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: inherit;
  }
  .inside_loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 35%;
    position: fixed;
    font-family: SansSB;
    padding: 12px;

  }
  .inside_loader p {
    text-align: center;
  }
  .language .MuiOutlinedInput-root {
    width: 225px;
    height: 35px;
  }
  @media only screen and (max-width: 600px) {
    .inside_loader {
      top: 50%;
    }
  }