@font-face {
  font-family: Sans;
  src: url(../../fonts/ProductSans-Regular.ttf);
}

@font-face {
  font-family: SansSB;
  src: url(../../fonts/ProductSans-Medium.ttf);
}

@font-face {
  font-family: SansB;
  src: url(../../fonts/ProductSans-Bold.ttf);
}

#sidebar {
  min-width: 190px;
  max-width: 190px;
  width: 60px;
  height: 100vh;
  box-shadow: 0 0 20px 0 rgba(8, 9, 9, 0.15);
  background-color: #048c88;
  color: #fff;
  transition: all 0.3s;
  position: fixed;
}

#sidebar.activexyz {
  /* transition: 0.3s; */
  min-width: 60px;
  max-width: 60px;
  text-align: center;
  background: #048c88;
}

#sidebar.activexyz p {
  display: none;
}

ul {
  list-style-type: none;
  /* margin: 0px !important;
    padding: 0px !important; */
}

#sideNavTop {
  margin-top: 15px !important;
  /* position: fixed; */
}

#sideNavTop li {
  margin-top: 0px !important;
  /* border-bottom: 1px solid gray; */
}
#sideNavTop #logoLi {
  margin-top: 0px !important;
}

#sideElements,
#sideElements1,
#sideElements2,
#sideElements3,
#sideElements4 {
  padding: 0px;
  width: inherit;
  height: 55px;
  border-radius: 0px;
}
#sidebar.activexyz #sideElements4:hover {
  background-color: #0f5755 !important;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}

#sidebar.activexyz .backColor {
  background-color: #0f5755;
  cursor: pointer;
  border-left: solid 3px #48c8c5;
}

#sideElements p,
#sideElements1 p,
#sideElements2 p,
#sideElements3 p,
#sideElements4 p {
  font-size: 14px;
  color: white;
  padding-left: 10px;
  padding-top: 2px;
  font-family: Sans;
  text-align: left;
  margin-left: 25px;
}

#sidebar.activexyz .indlogo {
  background-image: url(../../images/IN-D-FS-AppLogo.png);
  background-repeat: no-repeat;
  width: 42px;
  height: 36px;
  margin: 3px !important;
  display: inline-block;
}
.indlogo {
  background-image: url(../../images/IN-D-FS-AppLogo.png);
  background-repeat: no-repeat;
  width: 42px;
  height: 36px;
  margin: 3px !important;
  display: inline-block;
}

#sidebar.activexyz .Dashboard {
  background-image: url(../../images/dash.png);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 15px !important;
  display: inline-block;
}

#sidebar.activexyz .Settings {
  background-image: url(../../images/diagram.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 16px !important;
  display: inline-block;
}
#sidebar.activexyz .Users {
  background-image: url(../../images/user.png);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 16px !important;
  display: inline-block;
}
#sidebar.activexyz .Profile {
  background-image: url(../../images/Group.png);
  background-repeat: no-repeat;
  width: 30px;
  height: 24px;
  margin: 16px !important;
  display: inline-block;
}

#sideNavBottom {
  /* margin-top: 170px; */
  margin-bottom: 0px !important;
  bottom: 0px !important;
  position: fixed;
  width: inherit;
}

#sideNavBottom li {
  margin-bottom: 10px;
}

#sidebar.activexyz .Information {
  background-image: url(../../images/information.svg);
  background-repeat: no-repeat;
  width: 26px;
  height: 31px;
  margin: 15px !important;
  display: inline-block;
}

#sidebar.activexyz .Logout {
  background-image: url(../../images/logout.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 15px !important;
  display: inline-block;
}

#sidebar #collapseIcon {
  bottom: 0;
  background-color: #005a56;
  height: 45px;
  width: 190px;
  cursor: pointer;
  /* background-color: #BFF2F1; */
  transition: 0.3s;
}

#sidebar .openIcon {
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px !important;
  padding-bottom: 3px !important;
  /* padding-left: 150px; */
  position: fixed;
  margin-left: 150px;
  /* transition: 0.1s; */
  transform: rotate(180deg);
}

#sidebar.activexyz #collapseIcon {
  bottom: 0;
  background-color: #005a56;
  height: 45px;
  width: 60px;
  cursor: pointer;
}

#sidebar.activexyz .openIcon {
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding-top: 5px;

  transform: none;
  /* transition: 1s; */
  position: fixed;
  margin-left: 23px !important;
}

#sidebar.activexyz #tool {
  display: none;
}

.styles_modal__gNwvD {
  min-width: 500px;
  min-height: 300px;
  margin: 300px 700px;
}

#logoutButton {
  margin: 0px 10px !important;
  height: 32px;
  width: 70px;
  background-color: #048c88;
  border: #048c88 1px solid;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-family: Sans !important;
  padding: 0px !important;
}

.noDisp {
  display: none !important;
}

.logoutModal .modal-title {
  font-family: SansSB !important;
  font-size: 20px !important;
  color: rgb(4, 24, 23);
}

.logoutModal .modal-dialog {
  box-shadow: -8px 3px 6px #00000029 !important;
  width: 400px !important;
}

.logoutModal .modal-body {
  padding: 10px !important;
}

.logoutModal.modal {
  background: rgba(0, 0, 0, 0.6);
}
#mainContent {
  margin-left: 60px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  #sidebar.activexyz {
    margin-left: 0 !important;
    /* background-color: #fff; */
  }
  #sidebar .sidebar-header h3,
  #sidebar .CTAs {
    display: none;
  }
  #sidebar .sidebar-header strong {
    display: block;
  }

  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }

  #sidebar ul li a i {
    font-size: 1.3em;
  }
  #sidebar {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  #mainContent {
    margin-left: 0px;
  }
  .deletediv img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 700px) {
  #sidebar {
    display: none;
  }

  .navbar {
    display: flex !important;
    height: 64px;
    background-color: #ffffff;
    width: 100% !important;
    box-shadow: 0px 2px 3px #00000029;
    position: relative;
    z-index: 99;
  }
  #sideElements p,
  #sideElements1 p,
  #sideElements2 p,
  #sideElements3 p,
  #sideElements4 p {
    font-size: 18px;
    color: black;
    padding-left: 10px;
    padding-top: 17px;
    font-family: Sans;
    text-align: left;
    margin-left: 25px;
  }
  .navbar-collapse {
    background-color: white;
    margin-right: -16px;
    margin-left: -16px;
    margin-top: 10px;
  }
}
.navbar {
  display: none;
}
.bg-light {
  background-color: #048c88 !important;
}
.navbar-light .navbar-toggler {
  background-color: white;
}
