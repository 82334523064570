@font-face {
  font-family: Sans;
  src: url(../../fonts/ProductSans-Regular.ttf);
}

@font-face {
  font-family: SansSB;
  src: url(../../fonts/ProductSans-Medium.ttf);
}

@font-face {
  font-family: SansB;
  src: url(../../fonts/ProductSans-Bold.ttf);
}

@font-face {
  font-family: SansL;
  src: url(../../fonts/ProductSans-Light.ttf);
}

.main {
  font-family: Sans;
  background-color: #e2eaea !important;
  min-height: 100vh !important;
}

.registerMsg {
  position: absolute;
  text-align: center;
  width: 500px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 16px #425e5d1a !important;
  border-radius: 10px !important;
  background-color: #fff;
  padding: 20px;
}
.registerMsg {
  text-align: center;
}
.registerMsg h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
.mainLogo2 {
  margin: 15px auto !important;
  background-image: url(../../images/IN-D-Logo-LightBackground.png);
  background-repeat: no-repeat;
  height: 60px;
  width: 180px;
}
