.profiletabs {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin: 10px;
  border-radius: 5px;
  padding: 10px 0px 33px 0px;

}
.profiletabs .profile {
    width: 100px;
    border-radius: 50%;
    margin: 19px;
}
.tabs {
    margin-left: 17px;
  /* font-family: SansB; */
}
.tabs button {
    color: #098E8A;
}
.deletediv {
    padding: 16px 0px;
    font-family: SansB;
    align-items: center;
}
.deletediv img {
    margin-left: 20px;
    cursor: pointer;
}
/* @media (max-width: 768px) {
    .deletediv img {
        margin-left: 0px;
    }
} */
.changepassword .form-group {
    display: flex;
    flex-direction: column;
}
.changepassword button {
    margin: 15px;
    color: white !important;
}
.changepassword .form-control {
    width: 50%;
}
.tabshr {
  background-color: #d1d1d1;
  margin-left: -17px;
  margin-top: 0px;
}