@font-face {
    font-family: Sans;
    src: url(../../fonts/ProductSans-Regular.ttf);
}

@font-face {
    font-family: SansSB;
    src: url(../../fonts/ProductSans-Medium.ttf);
}

@font-face {
    font-family: SansB;
    src: url(../../fonts/ProductSans-Bold.ttf);
}

@font-face {
    font-family: SansL;
    src: url(../../fonts/ProductSans-Light.ttf);
}

a {
    text-decoration: none !important;
}

a:hover {
    text-decoration: none !important;
}

.wrapper {
    /* display: flex;
    align-items: stretch; */
    /* padding: 30px; */
    margin-left: 60px !important;
    /* overflow-x: hidden !important; */
    /* margin-top: 50px; */
}

.main {
    font-family: Sans;
    background-color: #E2EAEA !important;
    min-height: 100vh !important;
}

.row {
    max-width: 100% !important;
    margin: 0% !important;
}

#usersHead {
    height: 64px;
    background-color: #ffffff;
    width: 100% !important;
    box-shadow: 0px 2px 3px #00000029;
    position: relative;
    /* z-index: 99; */
}

#usersHead h5 {
    font-size: 18px;
    padding: 24px;
    padding-left: 22px !important;
    font-family: Sans;
    color: #2C2C2C;
    margin: 0px !important;
    /* width: fit-content; */
}

#usersMain {
    padding: 20px !important;
}

#usersMain #addUserDiv {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 30px !important;
}

#usersMain #addUserDiv h6 {
    text-align: left;
    font-size: 16px;
    font-family: SansSB;
    color: #2C2C2C;
}

#usersMain #addUserDiv .addUsertextInput {
    margin-top: 10px;
    padding: 5px !important;
    padding-left: 10px !important;
    width: 70%;
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D3D3D3;
    border-radius: 2px;
}

#usersMain #addUserDiv #addUserButton {
    margin-top: 10px;
    margin-left: 20px;
    width: 93px;
    height: 35px;
    color: #F6FEFE;
    border: #00716E 1px solid;
    border-radius: 4px;
    background-color: #00716E;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
}

#usersMain #addUserDiv #addUserNote p {
    padding-top: 15px;
    margin-bottom: 0px !important;
    text-align: left;
    font-size: 14px;
    font-family: Sans !important;
    color: #4E5757;
}
@media screen and (max-width:700px) {
    #usersMain #addUserDiv .addUsertextInput {
        width: 100%;
    }
    .row {
        display: flex;
        justify-content: center;
    }
    .wrapper {
        margin-left: 0 !important;
    }
    #usersHead {
        background-color: #E2EAEA !important;
        box-shadow: none;
        /* z-index: 99; */
    }
}

