@font-face {
    font-family: Sans;
    src: url(../../fonts/ProductSans-Regular.ttf);
}

@font-face {
    font-family: SansSB;
    src: url(../../fonts/ProductSans-Medium.ttf);
}

@font-face {
    font-family: SansB;
    src: url(../../fonts/ProductSans-Bold.ttf);
}

@font-face {
    font-family: SansL;
    src: url(../../fonts/ProductSans-Light.ttf);
}

a {
    text-decoration: none !important;
}

a:hover {
    text-decoration: none !important;
}

.wrapper {
    /* display: flex;
    align-items: stretch; */
    /* padding: 30px; */
    margin-left: 60px !important;
    /* overflow-x: hidden !important; */
    /* margin-top: 50px; */
}

.main {
    font-family: Sans;
    background-color: #E2EAEA !important;
    min-height: 100vh !important;
}

.row {
    max-width: 100% !important;
    margin: 0% !important;
}

#invoicesHead {
    height: 64px;
    background-color: #ffffff;
    width: 100% !important;
    box-shadow: 0px 2px 3px #00000029;
    position: relative;
    /* z-index: 99; */
}

#invoicesHead h5 {
    font-size: 18px;
    padding: 24px;
    padding-left: 22px !important;
    font-family: SansSB;
    color: #2C2C2C;
    margin: 0px !important;
    /* width: fit-content; */
}

#billingMain {
    padding: 20px !important;
}

#billingMain .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 20px;
    /* grid-row-gap: 20px; */
    align-content: space-evenly;
    /* padding: 10px; */
}

#topCards #topCardsEach {
    background-color: white;
    /* width: 285px; */
    height: 150px;
    border-radius: 10px !important;
    position: relative;
    box-shadow: 0px 4px 16px #43828040;
    padding: 20px;
}

#topCardsEach h5 {
    font-size: 16px;
    font-family: SansSB;
    color: #122626;
}

#topCardsEach p {
    font-size: 12px;
    font-family: Sans;
    color: #516463;
    margin-top: 15px !important;
    margin-bottom: 0px !important;
}

#topCardsEach h3 {
    font-size: 30px;
    font-family: SansSB;
    color: #122626;
    margin-bottom: 0px !important;
}

#topCardsEach #tax {
    font-size: 12px;
    font-family: Sans;
    color: #516463;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

#topCardsEach #manageButton {
    height: 32px;
    width: 70%;
    background-color: #048C88;
    border: #048C88 1px solid;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
    margin-top: 15px;
}

#topCardsEach #billingAdmin {
    margin-top: 25px !important;
}

#topCardsEach #billingAdmin #nameCircle {
    background-color: #CFE1E0;
    height: 56px;
    width: 56px;
    border-radius: 28px;
    padding: 15px !important;
}

#topCardsEach #billingAdmin #nameCircle h5 {
    font-size: 22px;
    font-family: SansB;
    color: #162928;
}

#topCardsEach #billingAdmin #nameTag {
    margin-left: 15px !important;
}

#topCardsEach #billingAdmin #nameTag h6 {
    font-size: 14px;
    font-family: SansSB;
    color: #122626;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

#topCardsEach #billingAdmin #nameTag p {
    font-size: 13px;
    font-family: Sans;
    color: #516463;
    margin-top: 3px !important;
}

#topCardsEach #billingAdmin #adminEdit {
    border: #215353 1px solid;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    margin-left: 25px !important;
    margin-top: 13px !important;
    padding: 2px !important;
    padding-left: 8px !important;
    cursor: pointer;
}

#topCardsEach #walletButton {
    height: 32px;
    width: 30%;
    background-color: #048C88;
    border: #048C88 1px solid;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
    margin-top: 10px;
}

/* Tables */

#billingTables {
    background-color: white;
    width: 100%;
    /* height: 400px; */
    margin-top: 20px !important;
    border-radius: 10px;
}

#billingTables #billingNav {
    padding: 10px !important;
    height: 50px;
    width: 100%;
    border-bottom: #D2DFDF 1px solid;
    padding-top: 15px !important;
    padding-left: 20px !important;
}

#billingTables #billingNav h6 {
    font-size: 15px;
    color: #516463;
    font-family: SansSB;
    padding: 5px !important;
    padding-bottom: inherit !important;
    margin-right: 60px !important;
}

#billingTables #billingNav h6:hover {
    border-bottom: #098E8A 2px solid;
    color: #098E8A !important;
    cursor: pointer;
}

#billingTables #billingNav h6.selected {
    border-bottom: #098E8A 2px solid;
    color: #098E8A !important;
    cursor: pointer;
}

#billingTables #billingSearch {
    padding: 10px !important;
    height: 50px;
    width: 100%;
    padding-left: 20px !important;
    position: relative;
}

#billingTables #billingSearch input {
    padding-left: 30px !important;
    border-radius: 2px;
    border: #BED2D2 1px solid;
    height: 32px;
}

#billingTables #billingSearch input::placeholder {
    color: #D4D4D4 !important;
    font-size: 15px !important;
    font-family: Sans !important;
}

#billingTables #billingSearch #searchIcon {
    color: #376e6e !important;
    padding: 8px !important;
    padding-left: 10px !important;
    position: absolute !important;
    font-size: 14px !important;
}

#billingTables #billingSearch #exportButton {
    height: 32px;
    width: 84px;
    background-color: white;
    border: #048C88 1px solid;
    border-radius: 4px;
    color: #048C88;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
    /* margin-top: 10px; */
    position: absolute;
    right: 20px !important;
}

#billingTables #billingSearch #exportButton:hover {
    color: white;
    background-color: #048C88;
}

/* #billingTables .MuiPaper-elevation4 {
    box-shadow: none !important;
} */

#billingTables .MuiTableCell-head {
    background-color: #DFF2F1 !important;
    padding: 10px !important;
    padding-left: 20px !important;
    color: #122626 !important;
    font-size: 12px !important;
    font-family: SansSB !important;
}

#billingTables .MuiTableCell-body {
    color: #516463 !important;
    font-size: 14px !important;
    font-family: Sans !important;
    padding-left: 20px !important;
}

/* Modal */

.manageCard.modal .modal-dialog {
    position: fixed;
    top: 0px;
    right: 0px;
    margin: 0px;
    width: 500px;
    height: -webkit-fill-available !important;
    box-shadow: -8px 3px 6px #00000029 !important;
}

.manageCard.modal .modal-title {
    color: #132121 !important;
    font-size: 18px !important;
    font-family: SansSB !important;
}

.manageCard.modal .modal-content {
    height: inherit !important;
    border-radius: 0px !important;
}

.manageCard.modal .manageBody {
    padding: 10px !important;
}

.manageCard.modal .manageBody .addCard {
    background-color: #EBFBFA !important;
    width: 100%;
    height: 150px;
    border-radius: 10px;
}

.manageCard.modal .manageBody .addCard #addCardButton {
    height: 32px;
    width: 35%;
    background-color: #048C88;
    border: #048C88 1px solid;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
    margin-top: 50px;
}

.manageCard.modal .manageBody .addCard p {
    color: #516463;
    font-size: 14px;
    font-family: Sans !important;
    text-align: center;
    margin-top: 10px;
}

.manageCard.modal .manageBody .creditCard {
    width: 100%;
    height: 175px;
    border-radius: 10px;
    border: #C6D1D0 1px solid;
    margin-bottom: 30px !important;
}

.manageCard.modal .manageBody .creditCard #ccRow {
    padding: 25px !important;
    padding-top: 35px !important;
    border-bottom: 1px solid #C6D1D0;
}

.manageCard.modal .manageBody .creditCard #ccRow h5 {
    color: #122626;
    font-size: 20px;
    font-family: SansSB !important;
    margin-left: 15px !important;
    margin-bottom: 0px !important;
}

.manageCard.modal .manageBody .creditCard #ccRow p {
    color: #516463;
    font-size: 12px;
    font-family: Sans !important;
    margin-left: 15px !important;
    margin-bottom: 10px !important;
}

.manageCard.modal .manageBody .creditCard #editRow {
    padding: 20px;
    position: relative;
}

.manageCard.modal .manageBody .creditCard #innerRow {
    position: absolute;
    right: 15px;
}

.manageCard.modal .manageBody .creditCard #innerRow h6 {
    color: #048C88;
    font-size: 15px;
    font-family: SansSB !important;
    margin: 0px 17px !important;
    cursor: pointer;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

/* Add credit card modal */

.addModal.modal {
    background: rgba(0, 0, 0, 0.6);
}

.addModal.modal .modal-dialog {
    position: fixed;
    top: 0px;
    right: 0px;
    margin: 0px;
    width: 400px;
    height: -webkit-fill-available !important;
}

.addModal.modal .modal-title {
    color: #132121 !important;
    font-size: 18px !important;
    font-family: SansSB !important;
}

.addModal.modal .modal-content {
    height: inherit !important;
    border-radius: 0px !important;
}

.addModal.modal .modal-body {
    padding: 20px !important;
}

.addModal.modal label.form-label {
    padding-top: 0px !important;
    font-family: Sans !important;
    font-size: 14px !important;
    color: #122626 !important;
}

.addModal.modal .form-control {
    height: 32px !important;
    color: #516463 !important;
    font-family: Sans !important;
    font-size: 14px !important;
    /* color: #122626 !important; */
}

.addModal.modal .form-group {
    margin-bottom: 15px !important;
}

.addModal.modal .modal-footer {
    background-color: #EDF3F3;
    justify-content: flex-start !important;
    padding-left: 20px !important;
}

.addModal.modal #saveButton {
    height: 32px;
    width: 70px;
    color: white;
    border: #048C88 1px solid;
    border-radius: 4px;
    background-color: #048C88;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
}

.addModal.modal #cancelButton {
    height: 32px;
    width: 85px;
    background-color: white;
    border: #BDC8C8 1px solid;
    border-radius: 4px;
    color: #048C88;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
    margin-left: 10px !important;
}

/* Wallet modal */

.walletModal.modal .modal-dialog {
    position: fixed;
    top: 0px;
    right: 0px;
    margin: 0px;
    width: 500px;
    height: -webkit-fill-available !important;
    box-shadow: -8px 3px 6px #00000029 !important;
}

.walletModal.modal .modal-title {
    color: #132121 !important;
    font-size: 18px !important;
    font-family: SansSB !important;
}

.walletModal.modal .modal-content {
    height: inherit !important;
    border-radius: 0px !important;
}

.walletModal.modal .modal-body {
    padding: 0px !important;
}

.walletModal.modal .couponDiv {
    background-color: #E5F9F8;
    height: 80px;
    width: 100%;
    padding: 25px !important;
}

.walletModal.modal .couponDiv .form-control {
    height: 32px !important;
    /* color: #516463 !important; */
    font-family: Sans !important;
    font-size: 14px !important;
    color: #122626 !important;
}

.walletModal.modal .couponDiv input::placeholder {
    font-family: Sans !important;
    font-size: 14px !important;
    color: #122626 !important;
}

.walletModal.modal .couponDiv #redeemButton {
    height: 32px;
    width: 93px;
    color: white;
    border: #048C88 1px solid;
    border-radius: 4px;
    background-color: #048C88;
    text-align: center;
    font-size: 14px;
    font-family: Sans !important;
}


#usagePage{ margin-top: 20px; margin-left: -10px;}
#usagePage .container-fluid{ margin-bottom: 40px;}
#usagePage .container-fluid:last-child{ margin-bottom: 80px;}
html{background: #e2eaea;}
#usagePage .container-fluid .MuiPaper-root{ height: 100%;}
@media screen and (max-width:700px) {
    .MUIDataTableBodyCell-responsiveStackedSmall-190 {
        width: 50%;
        margin-left: 20px !important;
}
#invoicesHead {
    background-color: #E2EAEA !important;
    box-shadow: none;
}
}
.tablemargin {
    margin-top: 10px;
}